import React, { Fragment, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormApi } from "../api/form_api";

function UseAuth({ children }) {
  const formApi = new FormApi("user/verify-jwt");
  const location = useLocation();
  const navigation = useNavigate();

  useEffect(() => {
    const code = localStorage.getItem("asdf");
    // if (location.pathname !== "/login") {
    if (!code) {
      if (location.pathname !== "/login") navigation("/login");
    } else {
      (async function () {
        try {
          const res = await formApi.verifyJwt(code);
          // console.log(res);
          // navigation("/anasayfa");
        } catch (error) {
          // console.log(error);
          navigation("/login");
        }
      })();
      // }
    }
  }, [location.pathname]);

  return <Fragment>{children}</Fragment>;
}

export default UseAuth;
