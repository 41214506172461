import React, { useState, useEffect } from "react";

function HomePage() {
  return (
    <div className="p-3">
      <div className="homepage">
        <div class="container-2">
          <div class="card-2">
            <div class="box-2">
              <div class="content-2">
                <h2>Blog</h2>
                <h3>Blog</h3>

                <p>
                  Blog sayfanızla ilgili tüm düzenlemelerinizi bu bölümde
                  yapabilirsiniz.
                </p>
                <a href="/blog">Giriş</a>
              </div>
            </div>
          </div>
          <div class="card-2">
            <div class="box-2">
              <div class="content-2">
                <h2>Basın</h2>
                <h3>Basın</h3>

                <p>
                  Basın sayfanızla ilgili tüm düzenlemelerinizi bu bölümde
                  yapabilirsiniz.
                </p>
                <a href="/basin">Giriş</a>
              </div>
            </div>
          </div>
        </div>
        <div class="container-2">
          <div class="card-2">
            <div class="box-2">
              <div class="content-2">
                <h2>Vajinusmus</h2>
                <h3>Vajinusmus</h3>

                <p>
                  Vajinusmus sayfanızla ilgili tüm düzenlemelerinizi bu bölümde
                  yapabilirsiniz.
                </p>
                <a href="/vajinusmus">Giriş</a>
              </div>
            </div>
          </div>
          <div class="card-2">
            <div class="box-2">
              <div class="content-2">
                <h2>SSS</h2>
                <h3>SSS</h3>

                <p>
                  Sıkça sorulan sorular sayfanızla ilgili tüm düzenlemelerinizi
                  bu bölümde yapabilirsiniz.
                </p>
                <a href="/sss">Giriş</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
    </div>
  );
}

export default HomePage;
