import React from "react";
import { Modal } from "reactstrap";
import ReactHtmlParser from "react-html-parser";

function Zoomer(props) {
  const product = props.product;
  return (
    <Modal toggle={props.toggle} isOpen={props.modal}>
      <div className="container">
        <div className="row">
          <div
            className="col-lg-12 col-md-10 col-sm-10 border-right-zoom"
            style={{ display: "flex" }}
          >
            <div className="row">
              <h2>{product.name}</h2>

              <img
                className="zoom-img"
                src={`https://api.mujdegulkaraca.com.tr/uploads/${product.img}`}
                alt="test"
              />
              <tr>
                <p />

                <p />

                <p style={{ fontSize: "16px" }} className=" py-0">
                  {" "}
                  {ReactHtmlParser(product.info)}
                </p>
              </tr>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default Zoomer;
