import React from "react";
import osilogo from "../img/osicrew.webp";

const Footer = () => {
  return (
    <div className="footer d-flex justify-content-center">
      {" "}
      <a
        className="d-flex text-bold align-items-center"
        target="_blank"
        href="https://osicrew.com/"
      >
        made by &nbsp;
        <img
          className="osi-logo  d-flex justify-content-center"
          src={osilogo}
        ></img>
      </a>
    </div>
  );
};

export default Footer;
