import React, { useState, useEffect } from "react";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import alertify from "alertifyjs";
import Slider from "../components/Slider";
import { useLocation } from "react-router-dom";
import { FormApi } from "../api/form_api";

const dataSources = [];

const endpoint = "sss";

function FormPage() {
  const formApi = new FormApi(endpoint);
  const location = useLocation();

  const [form, setForm] = useState({
    sssNo: "",
    answer: "",
    name: "",
    img: "",
    video: "",
  });

  const onChangeHandler = (key, value) => {
    setForm((prevState) => ({ ...prevState, [key]: value }));
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();

    let formData = new FormData();
    formData.append("img", form.img);
    formData.append("video", form.video);
    formData.append("name", form.name);
    formData.append("sssNo", form.sssNo);
    formData.append("answer", form.answer);

    try {
      const rst = await formApi.addPage(formData);
      alertify.success(
        "Sıkça sorulan soru eklendi..",
        ((window.location.href = "/sss"), 5000),
        2.5
      );
    } catch (e) {
      alertify.error("Sıkça sorulan soru eklenemedi...", 5000);
    }
  };

  const onUpdateHandler = async (e) => {
    e.preventDefault();

    let formData = new FormData();
    formData.append("_id", form._id);
    formData.append("video", form.video);
    formData.append("img", form.img);
    formData.append("name", form.name);
    formData.append("sssNo", form.sssNo);
    formData.append("answer", form.answer);

    try {
      const rst = await formApi.updatePage(formData);
      alertify.success(
        "Düzenlemeler yapıldı..",
        ((window.location.href = "/sss"), 1000),
        2.5
      );
    } catch (e) {
      alertify.error("Düzenlemeler yapılamadı..", 1000);
    }
  };

  useEffect(() => {
    if (location.state)
      Object.entries(location.state.product).forEach(([key, value]) => {
        onChangeHandler(key, value);
      });
  }, []);

  const imageUpload = (data) => {
    setForm((prevState) => ({ ...prevState, ["img"]: data }));
  };

  const videoUpload = (data) => {
    setForm((prevState) => ({ ...prevState, ["video"]: data }));
  };

  return (
    <div className="container mt-3 pb-5">
      <h4>Sıkça Sorulan Sorular Ekleme Paneli</h4>
      <hr />

      <Form onSubmit={onSubmitHandler}>
        <br />
        <Label>
          <b>
            "Video ekleyecekseniz" bu alana video icin kullanacağınız posteri
            ekleyin.
          </b>
        </Label>
        <Slider imageUpload={imageUpload} data={dataSources} />
        <Label>
          <b>"Video ekleyecekseniz" bu alana kullanacağınız videoyu ekleyin.</b>
        </Label>
        <input
          type="file"
          accept="video/*"
          onChange={(e) => videoUpload(e.target.files[0])}
        />
        <br />
        <div className="row col-12">
          <div className="col-12">
            <FormGroup>
              <Label for="sssNo">
                <b>Soru Numarası</b>
              </Label>
              <Input
                id="sssNo"
                name="sssNo"
                placeholder="Lütfen soru numarasını giriniz..."
                type="number"
                onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
                value={form.sssNo}
                min={0}
              />
            </FormGroup>
            <FormGroup>
              <Label for="name">
                <b>Sorunuzu yazınız</b>
              </Label>
              <Input
                id="name"
                name="name"
                placeholder="Lütfen sorunuzu giriniz..."
                type="text"
                onChange={(e) => onChangeHandler(e.target.name, e.target.value)}
                value={form.name}
              />
            </FormGroup>
          </div>
        </div>
        <Label for="answer">
          <b>
            Soru Cevabı(eğer video cevabı kullandıysanız bu alanı boş
            bırakabilirsiniz)
          </b>
        </Label>
        <CKEditor
          name="answer"
          editor={ClassicEditor}
          onReady={(editor) => {
            if (form.answer !== "") editor.setData(form.answer);
          }}
          onChange={(event, editor) =>
            onChangeHandler("answer", editor.getData())
          }
        />
        <br />
        <Button color="dark" type="submit" onClick={(e) => onSubmitHandler(e)}>
          Ekle
        </Button>
        {"  "}
        <Button type="submit" onClick={(e) => onUpdateHandler(e)}>
          Güncelle
        </Button>
      </Form>
    </div>
  );
}

export default FormPage;
