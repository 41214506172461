import React from "react";
import { Route, Routes } from "react-router-dom";

import NavBar from "./components/Navbar";
import Blog from "./Pages/Blog";

import FormBlog from "./Pages/FormBlog";
import FormBasin from "./Pages/FormBasin";
import FormVajinusmus from "./Pages/FormVajinusmus";
import FormSSS from "./Pages/FormSSS";
import Login from "./Pages/Login";

import Homepage from "./Pages/Homepage";
import UseAuth from "./hooks/UseAuth";
import "./App.css";
import Basin from "./Pages/Basin";
import Vajinusmus from "./Pages/Vajinusmus";
import SSS from "./Pages/SSS";
import Footer from "./components/Footer";

function App() {
  return (
    <div>
      <UseAuth>
        {" "}
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<NavBar />}>
            <Route path="/anasayfa" element={<Homepage />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/basin" element={<Basin />} />
            <Route path="/vajinusmus" element={<Vajinusmus />} />
            <Route path="/sss" element={<SSS />} />

            <Route path="form" element={<FormBlog />} />
            <Route path="form2" element={<FormBasin />} />
            <Route path="form" element={<FormBlog />} />
            <Route path="form3" element={<FormVajinusmus />} />
            <Route path="form4" element={<FormSSS />} />
          </Route>
        </Routes>{" "}
        <Footer />
      </UseAuth>
    </div>
  );
}

export default App;
